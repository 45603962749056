import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash';
import Layout from '../components/layout'
import SEO from '../components/seo'
import PostListing from '../components/PostListing';

const BlogIndexPage = (props) => {
  return (
    <Layout>
      <SEO title="blog" />
      <PostListing posts={_.get(props, 'data.allMarkdownRemark.edges', [])}/>
    </Layout>
  )
}

export default BlogIndexPage

export const pageaQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___createdAt], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            title
            createdAt
          }
        }
      }
    }
  }
`;
